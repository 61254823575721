import axios from 'axios';

axios.defaults.headers.common['Accept'] = 'application/json' // for all requests
axios.defaults.validateStatus = false;

const PAGARME_API = "https://api.pagar.me/"

export default {
    name: 'Pagarme',
    get_cardbin(initcard) {
        return axios.get(PAGARME_API + 'bin/v1/' + initcard);
    },
    get_card_token(number,holder_name,exp_month,exp_year,cvv,line_1,line_2,cep,city,state) {
        let card = {
            "number": number,
            "holder_name": holder_name,
            "exp_month": exp_month,
            "exp_year": exp_year,
            "cvv": cvv,
            "label": "Cartão Pagamento",
            "billing_address":{
                "line_1":line_1,
                "line_2":line_2,
                "zip_code":cep,
                "city":city,
                "state":state,
                "country":"BR"
            }
        };
        return axios.post(PAGARME_API + 'core/v5/tokens?appId=' + process.env.VUE_APP_PAGARME_APIKEY, {"type": "card","card": card})
    }
}
