<template>
  <HeaderHome />
  <div class="container-fluid body">
    <div class="container">
      <Loader :show="loading" />

      <div class="row body-box">
        <div class="col-md-12" ref="lote">
          <h1>Pagamento</h1>
        </div>

        <div class="row">
          <div class="col-md-8 bdr">

            <div class="col-md-12 formaspagto mt-4" ref="formaspagto">
              <div class="row">
                <div class="col-xs-12 mb-2">
                  <h2> Informações de Pagamento </h2>
                </div>
              </div>

              <div class="container-fluid form-box2">
                <!-- TABS -->
                <div class="row form-group">
                  <div class="col-xs-12">
                    <ul class="nav nav-pills nav-justified thumbnail setup-panel">
                      <li :class="current_step == 1 ? 'active' : ''">
                        <a @click.prevent="goto_step1" href="#step-1">
                          <h4 class="list-group-item-heading">Passo 1</h4>
                          <p class="list-group-item-text">Endereço de Cobrança</p>
                        </a>
                      </li>
                      <li :class="[step2 ? '' : 'disabled', current_step == 2 ? 'active' : '',]">
                        <a @click.prevent="goto_step2" href="#step-2">
                          <h4 class="list-group-item-heading">Passo 2</h4>
                          <p class="list-group-item-text">Pagamento</p>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>


                <!-- STEP1 -->
                <form @submit.prevent ref="formstep1">
                  <div class="row setup-content" v-show="current_step == 1">


                    <div class="col-xs-12">
                      <div class="col-md-12">
                        <div class="row">
                          <div class="col-md-6">
                            <h3>Endereço de Cobrança</h3>
                          </div>
                          <div class="col-md-6 text-right padding-h3">
                            * Campos obrigatórios.
                          </div>
                        </div>
                        <div class="col-md-12  mb-2">
                          <button class="btn btn-primary btn-block" @click.prevent="carrega_dados">Carregar os dados do
                            meu usuário</button>
                        </div>

                        <div class="col-md-8">
                          <div class="form-group">
                            <label>Rua/Avenida*</label>
                            <input v-model="shippingAddress.shippingAddressStreet" type="text" class="form-control"
                              required />
                          </div>
                        </div>

                        <div class="col-md-4">
                          <div class="form-group">
                            <label>Número*</label>
                            <input v-model="shippingAddress.shippingAddressNumber" type="text" class="form-control"
                              required />
                          </div>
                        </div>

                        <div class="col-md-8">
                          <div class="form-group">
                            <label>Bairro*</label>
                            <input v-model="shippingAddress.shippingAddressDistrict" type="text" class="form-control"
                              required />
                          </div>
                        </div>

                        <div class="col-md-4">
                          <div class="form-group">
                            <label>CEP*</label>
                            <input v-model="shippingAddress.shippingAddressPostalCode" @focusout="force_cep"
                              v-mask="'#####-###'" type="text" class="form-control" required />
                          </div>
                        </div>

                        <div class="col-md-6">
                          <div class="form-group">
                            <label>Estado*</label>
                            <select required class="form-control" v-model="shippingAddress.shippingAddressState"
                              @change="change_state">
                              <option value="">Selecione um Estado</option>
                              <option v-for="state in states" :value="state.uf" :key="state.id">
                                {{ state.name }}
                              </option>
                            </select>
                          </div>
                        </div>

                        <div class="col-md-6">
                          <div class="form-group">
                            <label>Cidade *</label>
                            <select v-model="shippingAddress.shippingAddressCity" required class="form-control">
                              <option value="">Selecione uma Cidade</option>
                              <option v-for="(city, index) in cities" :value="city" :key="index">
                                {{ city }}
                              </option>
                            </select>
                          </div>
                        </div>


                        <div class="row">
                          <div class="col-md-12 col-sm-12 text-right">
                            <button @click.prevent="next_step2" class="btn btn-success">
                              Próximo Passo
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>


                  </div>
                </form>

                <!-- STEP2 -->
                <form @submit.prevent ref="formstep2">
                  <div class="row setup-content" v-show="current_step == 2">
                    <div class="col-xs-12">
                      <div class="col-md-12">
                        <div class="row">
                          <div class="col-md-6">
                            <h3>Pagamento</h3>
                          </div>
                          <div class="col-md-6 text-right padding-h3">
                            * Campos obrigatórios.
                          </div>
                        </div>
                        <div class="row p15">
                          <div
                            :class="['col-md-4', 'col-sm-6', 'inscricoes', payment_method == payment.type ? 'selected' : '']"
                            v-for="(payment, index) in payment_methods" :key="index">
                            <div class="card card-default" @click="select_forma(payment)">
                              <div class="card-body text-center">
                                <p>{{ payment.name }}</p>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="row p15" v-if="payment_method == 'CREDIT_CARD'">
                          <div class="col-md-12">
                            <form @submit.prevent ref="creditcard">
                              <div class="row">
                                <div class="col-md-12">
                                  <div class="form-group">
                                    <label>Número do cartão</label>
                                    <input type="text" name="cardnumber" @keyup="get_cardinfos"
                                      @focusout="getInstallments" v-model="card.number"
                                      v-mask='["#### #### #### ####", "#### #### #### #### ###"]' class="form-control"
                                      autocomplete="cc-number" required>
                                  </div>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-md-6">
                                  <div class="form-group">
                                    <label>Data de Validade</label>
                                    <input type="text" name="cc-exp" v-model="card.validade" @focusout="validate_fields"
                                      v-mask="'##/##'" class="form-control" placeholder="__/__" required
                                      autocomplete="cc-exp">
                                  </div>
                                </div>
                                <div class="col-md-6">
                                  <div class="form-group">
                                    <label>Codigo CVC</label>
                                    <input type="text" name="cvc" v-model="card.security_code" @focusout="validate_fields"
                                      v-mask="['###', '####']" class="form-control" required autocomplete="cc-csc"
                                      :maxlength="this.card_infos.cvv">
                                  </div>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-md-6">
                                  <div class="form-group">
                                    <label>Nome (igual no cartão)</label>
                                    <input type="text" name="ccname" v-model="creditCardHolder.creditCardHolderName"
                                      class="form-control" autocomplete="cc-name" required>
                                  </div>
                                </div>
                                <div class="col-md-6">
                                  <div class="form-group">
                                    <label>Data de nascimento do dono</label>
                                    <input type="text" name="ccname" placeholder="__/__/____" v-mask="'##/##/####'"
                                      @focusout="validate_fields" v-model="creditCardHolder.creditCardHolderBirthDate"
                                      class="form-control" autocomplete="cc-name" required>
                                  </div>
                                </div>
                                <div class="col-md-6">
                                  <div class="form-group">
                                    <label>CPF do dono</label>
                                    <input v-model="creditCardHolder.creditCardHolderCPF" @focusout="ValidCPF" type="text"
                                      class="form-control" required placeholder="___.___.___-__" v-mask="'###.###.###-##'"
                                      autocomplete="off" maxlength="14" />
                                  </div>
                                </div>
                                <div class="col-md-6">
                                  <div class="form-group">
                                    <label>Telefone do dono</label>
                                    <input v-model="creditCardHolder.creditCardHolderPhone" type="text"
                                      @focusout="validate_fields" class="form-control" required=""
                                      v-mask="['(##) ####-####', '(##) #####-####']" autocomplete="off" maxlength="15" />
                                  </div>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-md-12">
                                  <div class="form-group">
                                    <label>Parcelas</label>
                                    <select v-if="buildInstallmentSelect.length" @change="parcelas"
                                      v-model="parcelas_item" name="parcelas" class="form-control"
                                      :disabled="buildInstallmentSelect.length == 0">
                                      <option :value="index" v-for="(parcelas, index) in buildInstallmentSelect"
                                        :key="parcelas['quantity']">
                                        {{ parcelas['quantity'] + "x de R$" +
                                          parcelas['installmentAmount'].toFixed(2).replace('.', ',') + " - Total de R$" +
                                          parcelas['totalAmount'].toFixed(2).replace('.', ',') }}
                                      </option>
                                    </select>
                                    <select class="form-control" v-else disabled>
                                      <option value=""></option>
                                    </select>
                                  </div>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>

                        <div class="row" v-show="payment_method">
                          <div class="col-md-12 col-sm-12 p15 text-right">
                            <button @click.prevent="pay" class="btn btn-success">
                              FINALIZAR PAGAMENTO
                            </button>
                          </div>
                        </div>


                      </div>
                    </div>
                  </div>
                </form>

              </div>
            </div>
          </div>


          <!-- Resumo Pedido -->
          <div class="col-md-4">
            <div class="col-md-12">
              <h2>Resumo</h2>
              <hr>
            </div>

            <div class="col-md-12" v-for="(order, index) in orders_infos" :key="index">
              <div class="row">
                <div class="col-md-8 col-xs-8">1x {{ order.kit.name }} - {{ orders[index].tamanho }}</div>
                <div class="col-md-4 col-xs-4 text-right">R${{ new Intl.NumberFormat('pt-BR', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
                }).format(order.kit.price) }}</div>
              </div>
              <hr>
            </div>

            <div class="col-md-12 valor">
              <h3>
                <div class="row">
                  <div class="col-md-8 col-xs-7 text-right">
                    <span>Taxa de Inscrição:</span>
                  </div>
                  <div class="col-md-4 col-xs-5 text-right">
                    <span>R$ {{ new Intl.NumberFormat('pt-BR', {
                      minimumFractionDigits: 2, maximumFractionDigits:
                        2
                    }).format(sys_tax) }}</span>
                  </div>
                </div>
              </h3>
            </div>

            <div class="col-md-12 valor">
              <h3 v-if="select_parcelas && select_parcelas.quantity != 1">
                <div class="row">
                  <div class="col-md-8 col-xs-7 text-right">
                    <b>Valor do Pedido:</b>
                  </div>
                  <div class="col-md-4 col-xs-5">
                    <span>R$ {{ new Intl.NumberFormat('pt-BR', {
                      minimumFractionDigits: 2, maximumFractionDigits:
                        2
                    }).format(price_order) }}</span>
                  </div>
                </div>

                <div class="row mt-2">
                  <div class="col-md-8 col-xs-7 text-right">
                    <b>{{ select_parcelas.quantity }}x de :</b>
                  </div>
                  <div class="col-md-4 col-xs-5 text-right">
                    <span>R$ {{ new Intl.NumberFormat('pt-BR', {
                      minimumFractionDigits: 2, maximumFractionDigits:
                        2
                    }).format(select_parcelas.installmentAmount) }}</span>
                  </div>
                </div>
                <hr>
                <div class="row mt-4">
                  <div class="col-md-8 col-xs-7">
                    <b>Total á Pagar:</b>
                  </div>
                  <div class="col-md-4 col-xs-5 text-right">
                    <span>R$ {{ new Intl.NumberFormat('pt-BR', {
                      minimumFractionDigits: 2, maximumFractionDigits:
                        2
                    }).format(select_parcelas.totalAmount) }}</span>
                  </div>
                </div>
              </h3>
              <h3 v-else>
                <div class="row mt-2">
                  <div class="col-md-8 col-xs-7">
                    <b>Total á Pagar:</b>
                  </div>
                  <div class="col-md-4 col-xs-5 text-right">
                    <span>R$ {{ new Intl.NumberFormat('pt-BR', {
                      minimumFractionDigits: 2, maximumFractionDigits:
                        2
                    }).format(price_order) }}</span>
                  </div>
                </div>
              </h3>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>

  <Newsletter />
  <Footer />
  <FooterEnd />
</template>

<script>
import { defineComponent } from "vue";
import HeaderHome from "../components/HeaderHome";
import Footer from "../components/Footer.vue";
import FooterEnd from "../components/FooterEnd.vue";
import Newsletter from "../components/Newsletter.vue";
import Loader from "../components/Loader";
import Orders from "../api/Orders";
import Carrinho from "../api/Carrinho";
import Auth from "../api/Auth";
import { mask } from "vue-the-mask";

import Pagarme from "../api/Pagarme";

import States from "../api/States";
import Cities from "../api/Cities";
import Swal from "sweetalert2";

export default defineComponent({
  name: "PagamentoPage",
  directives: { mask },
  components: {
    HeaderHome,
    Footer,
    FooterEnd,
    Newsletter,
    Loader
  },
  computed: {},
  data() {
    return {
      PagSeguroDirectPayment: null,
      loading: true,
      step1: true,
      step2: false,
      step3: false,
      current_step: 1,
      "sender": {
        'senderHash': '',
      },
      "shippingAddress": {
        'shippingAddressStreet': '',
        'shippingAddressNumber': '',
        'shippingAddressDistrict': '',
        'shippingAddressPostalCode': '',
        'shippingAddressCity': '',
        'shippingAddressState': ''
      },
      "creditCardHolder": {
        'creditCardHolderName': '',
        'creditCardHolderPhone': '',
        'creditCardHolderCPF': '',
        'creditCardHolderBirthDate': '',
      },
      "orders": [],
      "orders_infos": [],
      "payment_method": null,
      "payment_methods": [
        { id: 2, name: "Cartão de crédito", type: 'CREDIT_CARD' },
        { id: 1, name: "Boleto Bancário", type: 'BOLETO' },
        { id: 3, name: "PIX", type: 'PIX' },
      ],
      "pagseguro": {
        'SenderHash': '',
      },
      "price_order": 0,
      "select_parcelas": { "interestFree": null, "quantity": 1, "installmentAmount": null, "totalAmount": null },
      "parcelas_item": 0,
      'buildInstallmentSelect': [],
      "states": [],
      "cities": [],
      "sys_tax": 0.05,
      "card_infos": {
        "brand": "",
        "brandImage": "",
        "brandName": "",
      },
      "card_token": {},
      "card": {
        "error": null,
        "token": "",
        "number": "",
        "exp_month": "",
        "exp_year": "",
        "security_code": "",
        "validade": '',
        "holder": {
          "name": ""
        },
        'brand': "",
      }
    };
  },
  methods: {
    parcelas() {
      this.select_parcelas = this.buildInstallmentSelect[this.parcelas_item];
    },
    force_cep() {
      if (this.shippingAddress.shippingAddressPostalCode.length != 9) {
        this.shippingAddress.shippingAddressPostalCode = "";
      }
    },
    goto_step1() {
      this.step1 = true;
      this.current_step = 1;
    },
    goto_step2() {
      if (this.step2 == true) {
        this.current_step = 2;
      }
    },
    goto_step3() {
      if (this.step3 == true) {
        this.current_step = 3;
      }
    },
    next_step2() {
      if (this.$refs.formstep1.checkValidity() == false) {
        this.$refs.formstep1.reportValidity();
        return false;
      }

      this.step2 = true;
      this.current_step = 2;

    },
    next_step3() {
      if (this.$refs.formstep2.checkValidity() == false) {
        this.$refs.formstep2.reportValidity();
        return false;
      }

      this.step3 = true;
      this.current_step = 3;
    },
    carrega_dados() {
      let user = Auth.user();
      let UserBday = user.birthday.replace("T00:00:00.000000Z", "");
      UserBday = UserBday.split('-');
      UserBday = UserBday[2] + '/' + UserBday[1] + '/' + UserBday[0];

      this.sender = {
        'senderName': user.full_name,
        'senderPhone': user.telephone,
        'senderEmail': user.email,
        'senderCPF': user.cpf,
        'senderBirthDate': UserBday
      };

      this.creditCardHolder = {
        'creditCardHolderName': user.full_name, //Deve conter nome e sobrenome
        'creditCardHolderPhone': user.telephone, //Código de área enviado junto com o telefone
        'creditCardHolderCPF': user.cpf, //Ou CNPJ se for Pessoa Júridica
        'creditCardHolderBirthDate': UserBday,
      }

      let state = this.states.find((value) => {
        if (value.id == user.state_id) {
          return value;
        }
      });

      Cities.get_cities(state.id).then((result) => {
        if (result.status == 200) {
          this.cities = result.data;
          this.shippingAddress = {
            'shippingAddressStreet': user.street,
            'shippingAddressNumber': user.number,
            'shippingAddressDistrict': user.neighborhood,
            'shippingAddressPostalCode': "",
            'shippingAddressCity': this.cities[user.city_id],
            'shippingAddressState': state.uf,
          }
        }
      });

    },
    validate_fields() {
      if (this.card.number.length < 10) {
        this.card.number = "";
      }

      if (this.card.validade.length != 5) {
        this.card.validade = "";
      }


      if (this.card.security_code.length < 3) {
        this.card.security_code = "";
      }

      if (this.creditCardHolder.creditCardHolderBirthDate.length < 10) {
        this.creditCardHolder.creditCardHolderBirthDate = "";
      }

      if (this.creditCardHolder.creditCardHolderPhone.length < 14) {
        this.creditCardHolder.creditCardHolderPhone = "";
      }

    },
    pay() {
      if (this.payment_method == "") {
        return false;
      }

      if (this.payment_method == 'CREDIT_CARD') {

        this.validate_fields();

        if (this.$refs.creditcard.checkValidity() == false) {
          this.$refs.creditcard.reportValidity();
          return false;
        }
      }

      this.loading = true;
      //this.sender.senderHash = this.PagSeguroDirectPayment.getSenderHash();

      if (this.payment_method == 'CREDIT_CARD') {
        this.card_tokens((data) => {
          if (this.card.error) {
            this.loading = false;
            this.card.error = "";
            Swal.fire({
              icon: 'error',
              title: 'Erro nos dados do Cartão.',
              showConfirmButton: true,
            });
            return false;
          } else {
            let md5 = require('md5');
            let infos = {
              "orders": this.orders,
              "CreditCardHolder": this.creditCardHolder,
              "ShippingAddress": this.shippingAddress,
              "send": {
                "paymentMethod": this.payment_method,
                "creditCard": data,
                "installment": this.select_parcelas,
                "qh": md5(JSON.stringify(this.select_parcelas)),
              }
            }

            Orders.make_order(infos, Auth.token()).then((request) => {
              if (request.status == 200) {

                window.localStorage.setItem('last-cart', JSON.stringify(request.data));

                window.scrollTo(0, 0);
                Carrinho.limpar();
                this.$router.push('/sucesso/');
              }
            });
          }
        });

      } else {
        let infos = {
          "orders": this.orders,
          "CreditCardHolder": this.creditCardHolder,
          "ShippingAddress": this.shippingAddress,
          "send": {
            "paymentMethod": this.payment_method,
          }
        };

        Orders.make_order(infos, Auth.token()).then((request) => {
          if (request.status == 200) {
            if (request.data.success == false) {

              Swal.fire({
                icon: 'error',
                title: request.data.message,
                showConfirmButton: true,
              });

              this.loading = false;

            } else {
              if (request.data.boleto) {
                window.localStorage.setItem("BOLETO-LINK", request.data.boleto);
              }

              window.localStorage.setItem('last-cart', JSON.stringify(request.data));
              window.scrollTo(0, 0);

              Carrinho.limpar();
              this.$router.push('/sucesso/');

            }
          }
        });


      }
    },
    ValidCPF() {
      let valid = this.isValidCPF(this.creditCardHolder.creditCardHolderCPF);
      if (valid == false) {
        this.sender.senderCPF = "";
        this.creditCardHolder.creditCardHolderCPF = "";
      }
    },
    isValidCPF(cpf) {
      if (typeof cpf !== 'string') return false
      cpf = cpf.replace(/[^\d]+/g, '')
      if (cpf.length !== 11 || !!cpf.match(/(\d)\1{10}/)) return false
      cpf = cpf.split('')
      const validator = cpf
        .filter((digit, index, array) => index >= array.length - 2 && digit)
        .map(el => +el)
      const toValidate = pop => cpf
        .filter((digit, index, array) => index < array.length - pop && digit)
        .map(el => +el)
      const rest = (count, pop) => (toValidate(pop)
        .reduce((soma, el, i) => soma + el * (count - i), 0) * 10) % 11 % 10

      return !(rest(10, 2) !== validator[0] || rest(11, 1) !== validator[1])
    },
    goto_cart() {
      window.scrollTo(0, 0);
      this.$router.push('/carrinho/');
    },
    select_forma(payment_method) {
      this.payment_method = payment_method.type;

      this.select_parcelas = { "interestFree": null, "quantity": 1, "installmentAmount": null, "totalAmount": null };
      this.parcelas_item = 0;
      this.buildInstallmentSelect = [];
      this.card.number = "";
      this.card.security_code = "";
      this.card.validade = "";
    },
    get_cardinfos() {

      Pagarme.get_cardbin(this.card.number.replace(' ', '').substring(0, 6)).then((response) => {
        this.card_infos = response.data;
        this.card.brand = response.data.brand;
      });

    },
    card_tokens($function) {
      let param = {
        cardNumber: this.card.number.replaceAll(' ', ''),
        cvv: this.card.security_code,
        expirationMonth: this.card.validade.split('/')[0],
        expirationYear: '20' + this.card.validade.split('/')[1],
      }

      Pagarme.get_card_token(
        param.cardNumber,
        this.creditCardHolder.creditCardHolderName,
        param.expirationMonth,
        param.expirationYear,
        param.cvv,
        this.shippingAddress.shippingAddressStreet + " " + this.shippingAddress.shippingAddressNumber,
        '',
        this.shippingAddress.shippingAddressPostalCode,
        this.shippingAddress.shippingAddressCity,
        this.shippingAddress.shippingAddressState
      ).then((request) => {
        console.log(request);

        if (request.status == 200) {
          $function(request.data);
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Erro nos dados do Cartão.',
            showConfirmButton: true,
          });
          this.loading = false;
        }
      })
    },
    getInstallments() {
      if (this.card.number.length < 8) {
        this.card.number = "";
        return false;
      }

      if (this.card.brand == "") {
        this.card.number = "";
        return false;
      }

      if (this.card.number == "") {
        this.buildInstallmentSelect = [];
        this.select_parcelas = { "interestFree": null, "quantity": 1, "installmentAmount": null, "totalAmount": null };
        this.parcelas_item = 0;
        return false;
      }


      // this.PagSeguroDirectPayment.getInstallments({
      //   amount: (new Intl.NumberFormat('en', {minimumFractionDigits: 2,maximumFractionDigits :2}).format(this.price_order)),
      //   brand: this.card.brand,
      //   maxInstallmentNoInterest: 2, //calculo de parcelas sem juros
      //   success: (response) => {
      //     var installments = response['installments'][this.card.brand];
      let amount = (new Intl.NumberFormat('en', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(this.price_order));

      this.buildInstallmentSelect = [
        { "quantity": 1, "totalAmount": this.calcula_juros(amount, 1), "installmentAmount": this.calcula_juros(amount, 1), "interestFree": true },
        { "quantity": 2, "totalAmount": this.calcula_juros(amount, 2), "installmentAmount": (this.calcula_juros(amount, 2)) / 2, "interestFree": true },
        { "quantity": 3, "totalAmount": this.calcula_juros(amount, 3), "installmentAmount": (this.calcula_juros(amount, 3)) / 3, "interestFree": true },
        { "quantity": 4, "totalAmount": this.calcula_juros(amount, 4), "installmentAmount": (this.calcula_juros(amount, 4)) / 4, "interestFree": true },
        { "quantity": 5, "totalAmount": this.calcula_juros(amount, 5), "installmentAmount": (this.calcula_juros(amount, 5)) / 5, "interestFree": true },
        { "quantity": 6, "totalAmount": this.calcula_juros(amount, 6), "installmentAmount": (this.calcula_juros(amount, 6)) / 6, "interestFree": true },
        { "quantity": 7, "totalAmount": this.calcula_juros(amount, 7), "installmentAmount": (this.calcula_juros(amount, 7)) / 7, "interestFree": true },
        { "quantity": 8, "totalAmount": this.calcula_juros(amount, 8), "installmentAmount": (this.calcula_juros(amount, 8)) / 8, "interestFree": true },
        { "quantity": 9, "totalAmount": this.calcula_juros(amount, 9), "installmentAmount": (this.calcula_juros(amount, 9)) / 9, "interestFree": true },
        { "quantity": 10, "totalAmount": this.calcula_juros(amount, 10), "installmentAmount": (this.calcula_juros(amount, 10)) / 10, "interestFree": true },
        { "quantity": 11, "totalAmount": this.calcula_juros(amount, 11), "installmentAmount": (this.calcula_juros(amount, 11)) / 11, "interestFree": true },
        { "quantity": 12, "totalAmount": this.calcula_juros(amount, 12), "installmentAmount": (this.calcula_juros(amount, 12)) / 12, "interestFree": true }
      ];

      this.parcelas();
      //   },
      //   error: function (response) {
      //     console.log(response);
      //   }
      // })
    },
    calcula_juros(valor, parcelas) {
      valor = parseFloat(valor);
      parcelas = parseInt(parcelas);
      if (parcelas == 1) {
        return (valor * 1);
      }

      let valor_final = valor + ((valor / 100) * 2.66);
      for (let i = 1; i <= parcelas; i++) {
        valor_final = valor_final + ((valor / 100) * 1.09);
      }

      return valor_final * 1;

    },
    change_state() {
      let state = this.states.find((value) => {
        if (value.uf == this.shippingAddress.shippingAddressState) {
          return value;
        }
      });
      let cities = Cities.get_cities(state.id);

      cities.then((request) => {
        if (request.status == 200) {
          this.cities = request.data;
        }
      });
    },
  },
  mounted() {
    this.orders = Carrinho.get_itens();
    this.price_order = 0;

    // let pagseguroScript = document.createElement('script')
    // pagseguroScript.onload = () => {
    //   this.PagSeguroDirectPayment = window.PagSeguroDirectPayment;
    //   Pagseguro.get_session(Auth.token()).then((request) => {
    //     if (request.status == 200 && request.data.session != false) {
    //       this.PagSeguroDirectPayment.setSessionId(request.data.session);
    //       this.PagSeguroDirectPayment.onSenderHashReady((response2) => {
    //         if (response2.status == 'error') {
    //           console.log(response2.message);
    //           return false;
    //         }
    //         this.pagseguro.SenderHash = response2.senderHash;
    //       });
    //     }
    //   })
    // };
    // pagseguroScript.setAttribute('src',process.env.VUE_APP_PAGSEGURO_jS)
    // document.head.appendChild(pagseguroScript);

    let states = States.get_states();

    states.then((request) => {
      if (request.status == 200) {
        this.states = request.data;
      }
    });


    Orders.get_itens(this.orders, Auth.token()).then((request) => {
      if (request.status == 200) {
        this.orders_infos = request.data.orders;
        this.price_order = request.data.price;
        this.sys_tax = request.data.tax;
        if (this.price_order == 0) {

          window.scrollTo(0, 0);
          this.$router.push('/minha-conta/');
        }

        this.loading = false;
      }
    });

  }
});
</script>

<style scoped>
.img-card img {
  max-width: 100%;
  max-height: 100%;
}

.mt-4 {
  margin-top: 30px;
}

.mt-2 {
  margin-top: 15px;
}

.bdr {

  border-right: 1px solid #dadada;
}

.pr-4 {
  padding-right: 30px;
}

.p15 {

  padding-right: 15px;
  padding-left: 15px;
}

.card {
  border: 1px solid #dadada;
}

.col-centered {
  float: none;
  margin: 0 auto;
}

.mb-4 {
  margin-bottom: 30px;
}

.mb-2 {
  margin-bottom: 15px;
}

.price {
  padding-bottom: 6%;
  padding-top: 7%;

  padding-left: 0;

}

.valor span {
  color: #0f0f0f;

  font-size: 18px;
}

b {
  color: #eea72b;
}

.price span {
  color: #ac2925;
  cursor: pointer;
}

.inscricoes.selected .card {
  background-color: #ac2925;
  color: #fff;
  transition: background-color 1s ease;
}

.inscricoes .card p {
  font-size: 18px;

  padding: 0px 0;
}

.inscricoes .card {
  cursor: pointer;
}

.nav-pills>li.active>a,
.nav-pills>li.active>a:focus,
.nav-pills>li.active>a:hover {
  color: #fff;
  background-color: #ac2925;
}

.nav-pills>li>a {
  color: #ac2925;
  text-decoration: none;
}

.nav-pills>li.disabled>a {
  color: #777;
  text-decoration: none;
}

@media only screen and (max-width: 600px) {
  .valor b {
    font-size: 17px;
  }
}
</style>
